import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Page from "../components/global/Page/page"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query page404Data {
      contentfulComposePage(slug: {eq: "/404"}) {
        ...ComposePageFragment
      }
    }
  `)

  return (
    <Page data={data.contentfulComposePage} />
  )
}
export default NotFoundPage
